import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ModuleRenderer from "@components/module-renderer";
import ContestEntryForm from "@components/contest-modal/contest-entry-form.js";
import { get } from "lodash";
import ContestModal from "@components/contest-modal/index.js";
import { graphql } from "gatsby";

const IndexPage = ({ data }) => {
  const modules = get(data, "prismic.page.body");
  return (
    <Layout>
      <SEO title="Home" />
      <ModuleRenderer modules={modules} />
      {/* <ContestModal /> */}
      {/* <div style={{ display: "none" }}>
        <ContestEntryForm />
      </div> */}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query TemplatePageQuery {
    prismic {
      page(uid: "home", lang: "en-us") {
        body {
          ... on PRISMIC_PageBodyImage {
            type
            label
            primary {
              image
            }
          }
          ... on PRISMIC_PageBodyTour {
            type
            label
            primary {
              description
              headline
            }
          }
          ... on PRISMIC_PageBodyStore {
            type
            label
            primary {
              headline
              description
            }
          }
          ... on PRISMIC_PageBodyProducts_grid {
            type
            label
            primary {
              description
              headline
            }
          }
          ... on PRISMIC_PageBodySubscribe {
            type
            label
            primary {
              instructions
              call_to_action_text
              headline
            }
          }
          ... on PRISMIC_PageBodyContact {
            type
            label
            fields {
              email
              link {
                ... on PRISMIC__ExternalLink {
                  _linkType
                  url
                }
              }
              name
              subject
            }
            primary {
              headline
            }
          }
          ... on PRISMIC_PageBodyText {
            type
            label
            primary {
              headline
              content
            }
          }
          ... on PRISMIC_PageBodyMarquee {
            type
            label
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1100, quality: 76) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              primary_headline
              secondary_headline
            }
          }
          ... on PRISMIC_PageBodyTwo_column_cta {
            type
            label
            primary {
              headline
              left_text
              right_text
              cta_link {
                ... on PRISMIC__ExternalLink {
                  _linkType
                  url
                }
              }
              cta
            }
          }
          ... on PRISMIC_PageBodyVideos {
            type
            label
            primary {
              description
              headline
            }
          }
          ... on PRISMIC_PageBodySpacer {
            type
            label
          }
          ... on PRISMIC_PageBodyPhotos {
            type
            label
            fields {
              image
            }
            primary {
              instruction_text
              headline
            }
          }
        }
      }
    }
  }
`;
